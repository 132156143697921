import service from "@/utils/dmsrequest";
import dmsService from "@/utils/dmsrequest";
//获取渠道订单详情
// export function getOrderDetail(id) {
//   return service({
//     url: "findPurchaseOrderDetail",
//     data:{
//       purchaseOrderId:id
//     }
//   });
// }

// 获取零售订单详情
export function getOrderDetail(id) {
  return service({
    url: "getSalesOrderDetail",
    data: {
      salesOrderId: id,
      loginType: "CS"
    }
  });
}
// 取消物流
export function cancleLog(data) {
  return dmsService({
    url: 'omsOrderCancle',
    data
  })
}
export function getImg(data) {
  return dmsService({
    url: 'toOmsView',
    data
  })
}