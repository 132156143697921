import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import {
  getOrderDetail,
  getBHOOrderDetail
} from './api'
import {
  cancleOrderAll,
  cancleOrderLine
} from '../api'
import Process from '../components/Process.vue'
import processRetail from '../components/processRetail'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {
  addCart,
  cancleChannelOrder
} from "@/views/order/api";
import { downPoster } from "@/views/monopolyShopActivity/campaignList/api";
import { findUserIdByShopAndPhone, selectServiceFree } from "@/views/order/details/api";

export default {
  name: "purchaseOrderDetail",
  components: {
    ProductItem,
    Process,
    Btn,
    processRetail
  },
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "",
          name: "",
          title: "采购订单列表"
        },
        {
          path: "/channelOrder/detail",
          name: "detail",
          title: "订单详情"
        }
      ],
      orderDetail: {
        orderHeader: {},
      },
      pageLoadFlag: false,
      bg: bg,
      visible: false,
      fileList:[],
      serviceFeeTotal:0,
      brightNum:0,// 璀璨数量
      brightPrice:0,//璀璨价格
      olnyNum:0,// 专供数量
      olnyPrice:0,//专供价格
    };
  },
  computed: {
    totalProd: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty
        })
      }
      return parseInt(total || 0)

    },
    totalPrice: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty * (item.backnowledgedPrice * 100) / 100
        })
      }
      return total
    },
    totalLoadVolume: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty * Math.max(item.volume, 0)
        })
      }
      return total
    }

  },

  mounted() {
    if(this.$route.query.name == '销售订单进度汇总') {
      this.breadcrumbData[1].path = '/channelOrder/salesProgress'
      this.breadcrumbData[1].name = 'salesProgress'
      this.breadcrumbData[1].title = '销售订单进度汇总'
    }

    else if(this.$route.query.name == '销售订单列表') {
      this.breadcrumbData[1].path = '/channelOrder/orderList'
      this.breadcrumbData[1].title = '销售订单列表'
    }
    else {
      this.breadcrumbData[1].path = '/channelOrder/orderList'
      this.breadcrumbData[1].title = '采购订单列表'
    }


    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    } else {
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },

  methods: {
    // 退货单号 点击跳转退货单
    gotoDetail(item,type){
      this.$router.push({
        path:type=='zp'?'/salesReturn/genuineReturnDetails':'/hisenseReturn/hisenseReturnDetailNew',
        query: {
          id:item.orderId
        }
      })
    },
    toProDetail(proCode, orgId) {
      let routeUrl = this.$router.resolve({
        path: '/product/detail',
        query: {
          productCode: proCode,
          orgId: orgId
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    gotoRerurn() {
      let routeUrl = this.$router.resolve({
        path: '/channelReturns/returnEntry',
        query: {
          document: this. orderDetail.documentNum,
          // id: id
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 去支付
    handleToPay(item){
      if(item.feeList && item.feeList.length > 0) {
        let feeCode = item.feeList[0]
        this.$router.push({
          path: "/serviceFeeModule/payDetail",
          query: {
            id:feeCode,
            batch:0,
            b2bOrderCode: item.documentNum,
            orgName: '订单号',
            channel: 2
          }
        });
      } else {
        this.$message.warning('暂无待支付订单')
      }
    },
    // 下载
    downLoad(file){
      const a = document.createElement('a')
      a.setAttribute('download', file.fileRealName)
      a.setAttribute('target', '_blank')
      a.setAttribute('href', file.fileMapperPath)
      a.click()

    },
    // 下载文件
    handleRemove(file) {

      const a = document.createElement('a')
      a.setAttribute('download', file.fileRealName)
      a.setAttribute('target', '_blank')
      a.setAttribute('href', file.fileMapperPath)
      a.click()
    },
    // 取消订单
    cancleChannelOrderAll() {
      let param = {
        userAccount: this.account,
        purchaseOrderId: this.orderDetail.id
      }
      this.$emit('postSpinTrue', true)
      cancleChannelOrder(param).then(res => {
        this.$emit('postSpinTrue', false)
        let data = JSON.parse(res.data)
        if (data.code == 0) {
          this.$message.success('订单取消成功')
        } else {
          this.$message.success(data.msg)
        }
        this.getChannelList();
      }).catch(err => {
        console.log('err', err);
      })
    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      }
      addCart(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("加入购物车成功");
        } else {
          this.$message.warning(res.data.msg);
        }
      }).catch(error => {
        this.$message.warning('加入购物车失败');
        console.log('加入购物车', error);
      })
    },
    // 查看服务费
    gotoFee(item){
      this.$router.push({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item
        }
      });
    },
    // 查看用户档案
    gotoUser(){
      let data = {
        shopInfoId: null, // 门店id
        phone: this.orderDetail.contactInfo // 手机号
      }
      findUserIdByShopAndPhone(data).then(res=>{
        if (res.data.code == 0) {
          this.$router.push({
            path: "/IntentionDetail",
            query: {
              id:res.data.text
            }
          });

        }
        else {
          this.$message.warning(res.data.text)
        }
      })
    },
    gotoBhoDetail(code){
      let data = {
        orderCode:code
      }
      getBHOOrderDetail(data).then(res=>{
        if(res.data.orderHeader && res.data.orderHeader.orderId) {
          this.$router.push({
            path: "/order/detail",
            query: { id:  res.data.orderHeader.orderId }
          });
          return
        }
      })
    },
    getDetail(id) {
      this.pageLoadFlag = true
      getOrderDetail(id).then(res => {
        if (typeof res.data == 'string') {
          if(JSON.parse(res.data).code == 0) {
              
            this.orderDetail = JSON.parse(res.data).data
             // 获取专供
            const zg = this.orderDetail.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
            if(zg.length>0){
              zg.forEach(el=>{
                this.olnyNum+=Number(el.backnowledgedQty) 
                this.olnyPrice+=Number(el.backnowledgedQty) * Number(el.backnowledgedPrice);
              })
            }
           
            // 获取璀璨
            const cc = this.orderDetail.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
            if(cc.length>0){
            cc.forEach(el=>{
              this.brightNum+=Number(el.backnowledgedQty) 
              this.brightPrice+=Number(el.backnowledgedQty) * Number(el.backnowledgedPrice);
            })}
            let itemIds = this.orderDetail.salesOrderItem.map(it=>it.itemId)
            let data  = {
              orderType:2,
              orderItemIds: itemIds
            }
            selectServiceFree(data).then((resA)=>{
              console.log(resA);
              if(resA.data.code == 0) {
                this.serviceFeeTotal = resA.data.data.total
                resA.data.data.items.forEach(item=>{
                  this.orderDetail.salesOrderItem.forEach(itemTwo=>{
                    if(item.orderItemId == itemTwo.itemId) {
                      itemTwo.serviceFee = item.serviceFee
                    }
                  })
                })
              } else {
                this.serviceFeeTotal = 0
              }
            })
            if(this.orderDetail && this.orderDetail.attachmentList) {
              this.fileList =  this.orderDetail.attachmentList
            }
            this.pageLoadFlag = false
          } else {
            this.$message.warning(JSON.parse(res.data).msg)
            this.pageLoadFlag = false
          }
          this.pageLoadFlag = false
        } else {
          this.$message.warning(res.data.msg)
          this.orderDetail = res.data.data
          this.pageLoadFlag = false
        }
      }).finally(() => {
        this.pageLoadFlag = false
      })
    },
    cancel(type, item) {
      this.ordertypeCancel = type
      this.itemId = item && item.id
      this.cancelOrder(this.orderDetail.orderHeader)
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: '取消提醒',
          content: "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true
          },
          onCancel() {

          }
        });
      } else {
        this.visible = true
      }
    },
    getReason(value) {
      this.cancelReason = value
      const id = this.ordertypeCancel == 1 ? this.$route.query.id : this.itemId
      this.cancleSubmit(id, this.ordertypeCancel)
    },
    cancleSubmit(id, type) {
      this.pageLoadFlag = true
      let obj = {
        id: id,
        cancelReason: this.cancelReason
      }
      if (type == 2) {
        cancleOrderLine(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      } else {
        cancleOrderAll(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    getPrice(item) {
      return ((item.backnowledgedPrice || 0) * 100) * (item.backnowledgedQty || 0) / 100
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    gotoProcess() {
      this.$router.push({
        path: '/retailOrder/progress',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
};